import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  BooleanInput,
  TopToolbar,
  ListButton,
  ShowButton,
  // Toolbar,
  // SaveButton,
} from 'react-admin';

// import BackButton from '../../components/BackButton';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

// const ActionToolbar: FC<any> = (props) => (
//   <Toolbar {...props}>
//     <SaveButton
//       {...props}
//       label='Save and Show'
//       redirect='show'
//       submitOnEnter
//     />
//     <SaveButton
//       {...props}
//       label='Save'
//       redirect='list'
//       submitOnEnter={false}
//     />
//     <BackButton label='Cancel' />
//   </Toolbar>
// );

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm
      // toolbar={<ActionToolbar />}
      redirect='show'
    >
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ReferenceInput label='User' source='user' reference='users' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
