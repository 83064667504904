import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Description' source='description' />
      <ReferenceArrayField label='Locks' source='locks' reference='locks'>
        <SingleFieldList linkType='show'>
          <ChipField source='id' />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label='Keypads' source='keypads' reference='keypads'>
        <SingleFieldList linkType='show'>
          <ChipField source='id' />
        </SingleFieldList>
      </ReferenceArrayField>
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
