import * as React from 'react';
import { FC } from 'react';
import { Redirect } from 'react-router-dom';
import { useAuthState, Loading } from 'react-admin';
import {
  Box, Card, CardActions, Button, Typography,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
// import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';
// import FullCalendar from '@fullcalendar/react';
// import dayGridPlugin from '@fullcalendar/daygrid';

import config from '../../config';

// import backgroundImage from './welcome_bg.png';
// import publishArticleImage from './welcome_illustration.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#535353',
    // background: theme.palette.type === 'dark'
    //   ? '#535353'
    //   : `url(${backgroundImage}) no-repeat #6f4ceb`,
    color: '#fff',
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: '1em',
  },
  // media: {
  //   background: `url(${publishArticleImage}) top right / cover`,
  //   marginLeft: 'auto',
  // },
  actions: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap',
      '& a': {
        marginTop: '1em',
        marginLeft: '0!important',
        marginRight: '1em',
      },
    },
  },
}));

const BoxConfiguration: FC = () => {
  const classes = useStyles();
  const { loading, authenticated } = useAuthState();
  if (loading) return <Loading />;
  if (!authenticated) return <Redirect to={`${config.BASE_URL || ''}/`} />;
  return (
    <Card className={classes.root}>
      {/* <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
      /> */}
      <Box display='flex'>
        <Box flex='1'>
          <Typography variant='h5' component='h2' gutterBottom>
            BOXv1 Configuration
          </Typography>
          <Box maxWidth='40em'>
            <Typography variant='body1' component='p' gutterBottom>
              This page is under construction.
            </Typography>
          </Box>
          <CardActions className={classes.actions}>
            <Button
              variant='contained'
              href='https://homeit.io'
              startIcon={<HomeIcon />}
            >
              Homepage
            </Button>
          </CardActions>
        </Box>
        {/* <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          width="15em"
          overflow="hidden"
        >
          <Box height="9em" width="15em" className={classes.media} />
        </Box> */}
      </Box>
    </Card>
  );
};

export default BoxConfiguration;
