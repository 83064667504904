import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  TextField,
  SelectField,
  ReferenceField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import DateField from '../../components/DateField';

const ResourceShowActions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <CopyButton text={`Key Event ${(data || {}).id} | ${(data || {}).timestamp || (data || {}).dateCreated}`} />
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<ResourceShowActions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
      <DateField label='Timestamp' source='timestamp' showTime />
      <SelectField
        source='source'
        choices={[
          { id: 'keypad', name: 'Keypad' },
          { id: 'app', name: 'App (guest)' },
          { id: 'owner', name: 'App (host)' },
          { id: 'sms', name: 'SMS' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <ReferenceField label='Door' source='door' reference='doors' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceField label='Key' source='key' reference='keys' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceField label='User' source='guest' reference='users' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
