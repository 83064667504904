import axios, { AxiosRequestConfig } from 'axios';
import cookies from 'js-cookie';
import { DataProvider } from 'ra-core';
import { HttpError } from 'react-admin';
import qs from 'qs';

import config from '../config';

const isPlainObject = (v: any): v is object => Object
  .prototype.toString.call(v) === '[object Object]';

const merge = (a: any, b: any, mergeArrays?: boolean): any => ({
  ...a,
  ...(Object.keys(b).reduce((acc, v) => ({
    ...acc,
    // eslint-disable-next-line no-nested-ternary
    [v]: isPlainObject(a[v]) && isPlainObject(b[v])
      ? merge(a[v], b[v])
      : (mergeArrays === true && Array.isArray(a[v]) && Array.isArray(b[v])
        ? [...a[v], ...b[v]]
        : b[v]),
  }), {})),
});

const flattenObject = (v: any, path: string = ''): any => Object.entries(v)
  .reduce((acc, [field, value]) => ({
    ...acc,
    ...(isPlainObject(value) ? flattenObject(value, path ? `${path}.${field}` : field)
      : { [path ? `${path}.${field}` : field]: value }),
  }), {});

const unflattenObject = (v: any): any => Object.entries(v)
  .map(([key, value]) => key.split('.').reverse()
    .reduce((acc, k) => (Object.keys(acc).length === 0 ? { [k]: value } : { [k]: acc }), {}))
  .reduce((acc, o) => merge(acc, o), {} as any);

const contained = (a: any[], b: any[]): boolean => a.every((v) => b.includes(v));

const equalArray = (a: any[], b: any[]): boolean => (a.length === b.length)
  && contained(a, b);

const objectDifference = (a: object, b: object): object => {
  const flat = flattenObject(b);
  return unflattenObject(Object.entries(flattenObject(a))
    .filter(([key, value]) => {
      if (Array.isArray(value) && Array.isArray(flat[key])) return !equalArray(value, flat[key]);
      if (value instanceof Date && flat[key] instanceof Date) {
        return value.toISOString() !== flat[key].toISOString();
      }
      return value !== flat[key];
    })
    // TODO: replace undefined with null to remove fields
    // .map(([key, value]) => [key, value !== undefined ? value : null])
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {}));
};

// eslint-disable-next-line complexity
export const baseRequest = (url: string, resource: string, method: AxiosRequestConfig['method'],
  params?: any): Promise<any> => Promise.resolve(axios({
  url: `${url}${resource ? `/${resource}` : ''}${(params || {}).id ? `/${params.id}` : ''}`,
  method,
  data: (params || {}).previousData
    ? objectDifference((params || {}).data || {}, params.previousData)
    : (params || {}).data,
  headers: {
    Authorization: `Bearer ${cookies.get('authToken')}`,
    // 'User-Agent': 'homeit-backoffice',
  },
  params: {
    id: (params || {}).ids,
    ...((params || {}).filter || {}),
    ...((params || {}).sort ? {
      sortBy: ((params || {}).sort || {}).field || 'dateCreated',
      order: ((params || {}).sort || {}).order === 'DESC' ? 'desc' : 'asc',
    } : {}),
    ...((params || {}).pagination ? {
      limit: Math.max(Math.min(((params || {}).pagination || {}).perPage || 100, 100), 1),
      skip: Math.max((((params || {}).pagination || {}).page || 1) - 1, 0)
        * Math.max(Math.min(((params || {}).pagination || {}).perPage || 100, 100), 1),
    } : {}),
  },
  paramsSerializer: (p) => qs.stringify(p, { arrayFormat: 'repeat' }),
}));

export const request = (url: string, resource: string, method: AxiosRequestConfig['method'],
  params?: any) => {
  if ((Array.isArray(((params || {}).filter || {}).id) && params.filter.id.length === 0)
    || (Array.isArray((params || {}).ids) && params.ids.length === 0)) {
    return Promise.resolve({ data: [], total: 0 });
  }
  return baseRequest(url, resource, method, params)
    .then((response) => ({
      data: ((response.data || {}).docs || [])
        .map((d: any, i: number) => ((params || {}).index !== false ? { ...d, index: i + 1 } : d)),
      total: (response.data || {}).total || 0,
    }))
    .catch(() => Promise.reject(new HttpError('An error has occurred', 400)));
};

export const requestSingle = (url: string, resource: string, method: AxiosRequestConfig['method'],
  params?: any) => request(url, resource, method, params)
  .then(({ data }) => ({ data: data[0] }));

export const baseURLs = {
  CORE: config.BASE_URL_CORE || 'https://components.homeit.io/api-core',
  AUTH: config.BASE_URL_AUTH || 'https://components.homeit.io/api-auth',
  BILLING: config.BASE_URL_BILLING || 'https://components.homeit.io/billing',
  INTEGRATIONS: config.BASE_URL_INTEGRATIONS || 'https://components.homeit.io/integrations',
};

export const resourceURLs: { [resource: string]: string } = {
  boxes: baseURLs.CORE,
  doors: baseURLs.CORE,
  locks: baseURLs.CORE,
  keypads: baseURLs.CORE,
  properties: baseURLs.CORE,
  keys: baseURLs.CORE,
  keyevents: 'https://components.homeit.io/api-core',
  users: baseURLs.AUTH,
  organizations: baseURLs.AUTH,
  clients: baseURLs.AUTH,
  apikeys: baseURLs.AUTH,
  icalListings: `${baseURLs.INTEGRATIONS}/ical`,
  avantioCredentials: `${baseURLs.INTEGRATIONS}/avantio`,
  avantioListings: `${baseURLs.INTEGRATIONS}/avantio`,
};

export const resourceNames: { [resource: string]: string } = {
  icalListings: 'listings',
  avantioCredentials: 'credentials',
  avantioListings: 'listings',
};

export const provider: DataProvider = {
  getList: (resource, params) => (({} as any)[resource]
    || (() => (resourceURLs[resource]
      ? request(resourceURLs[resource], resourceNames[resource] || resource, 'GET', params)
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  getOne: (resource, params) => (({
    keys: () => baseRequest(resourceURLs[resource],
      resourceNames[resource] || resource, 'GET', params)
      .then((response) => ({
        data: {
          ...((response.data || {}).docs || [])[0],
          linkUrl: (((response.data || {})._links || {}).invitation || {}).href,
        },
      })),
    users: () => requestSingle(resourceURLs[resource],
      resourceNames[resource] || resource, 'GET', params)
      .then((user) => Promise.resolve(axios.get(`${baseURLs.BILLING}/customer`, {
        params: { user: params.id },
        headers: { Authorization: `Bearer ${cookies.get('authToken')}` },
      }))
        .then((billing) => ({ data: { ...user.data, billing: billing.data } }))),
  } as any)[resource] || (() => (resourceURLs[resource]
    ? requestSingle(resourceURLs[resource], resourceNames[resource] || resource, 'GET', params)
    : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  getMany: (resource, params) => (({} as any)[resource]
    || (() => (resourceURLs[resource]
      ? request(resourceURLs[resource], resourceNames[resource] || resource, 'GET', params)
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  getManyReference: (resource, {
    id, target, sort, ...params
  }) => (({} as any)[resource]
    || (() => (resourceURLs[target]
      ? request(`${resourceURLs[target]}/related/${resource}/${id}`,
        resourceNames[target] || target, 'GET', { ...params, index: false })
        .then((result) => ({ ...result, data: result.data.map((d: number) => ({ id: d })) }))
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  create: (resource, params) => (({} as any)[resource]
    || (() => (resourceURLs[resource]
      ? requestSingle(resourceURLs[resource], resourceNames[resource] || resource, 'POST', params)
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  update: (resource, params) => (({
    keys: () => {
      const { linkUrl, ...data } = params.data;
      return requestSingle(resourceURLs[resource], resourceNames[resource] || resource, 'PUT', {
        ...params,
        data,
        previousData: {
          ...params.previousData,
          // ...(data.isExpiring === true ? { checkOut: undefined } : {}),
          checkIn: undefined,
          checkOut: undefined,
        },
      });
    },
    users: () => {
      const { billing, ...data } = params.data;
      return requestSingle(resourceURLs[resource],
        resourceNames[resource] || resource, 'PUT', { ...params, data });
    },
  } as any)[resource]
    || (() => (resourceURLs[resource]
      ? requestSingle(resourceURLs[resource], resourceNames[resource] || resource, 'PUT', params)
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  updateMany: () => Promise.reject(new HttpError('Method not implemented', 501)),
  // updateMany: (resource, { ids, ...params }) => (({} as any)[resource]
  //   || (() => (resourceURLs[resource] ? Promise.all(ids || [])
  //     .map((id) => request(resourceURLs[resource],
  //       resourceNames[resource] || resource, 'PUT', { ...params, id }))
  //     : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  delete: (resource, params) => (({} as any)[resource]
    || (() => (resourceURLs[resource]
      ? requestSingle(resourceURLs[resource], resourceNames[resource] || resource, 'DELETE', params)
      : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),

  deleteMany: () => Promise.reject(new HttpError('Method not implemented', 501)),
  // deleteMany: (resource, { ids, ...params }) => (({} as any)[resource]
  //   || (() => (resourceURLs[resource] ? Promise.all(ids || [])
  //     .map((id) => request(resourceURLs[resource],
  //       resourceNames[resource] || resource, 'DELETE', { ...params, id }))
  //     : Promise.reject(new HttpError(`Resource is invalid: ${resource}`, 501)))))(),
};

export default provider;
