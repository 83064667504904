import * as React from 'react';
import { useGetManyReference } from 'react-admin';
import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import RelatedButton from '../../components/RelatedButton';
import boxes from '../boxes';
import doors from '../doors';
import properties from '../properties';
import apikeys from '../apikeys';
// import keys from '../keys';
import organizations from '../organizations';

const Aside: React.FC<any> = ({ record }) => {
  const { ids: boxIds, loading: boxLoading } = useGetManyReference('users', 'boxes',
    (record || {}).id, { page: 1, perPage: 100 }, {} as any, {}, 'boxes', {});
  const { ids: doorIds, loading: doorLoading } = useGetManyReference('users', 'doors',
    (record || {}).id, { page: 1, perPage: 100 }, {} as any, {}, 'boxes', {});
  return (
    <>
      <Box margin='0 0 1em 1em'>
        <Card>
          <Box margin='1em'>
            <Typography>
              Related resources
            </Typography>
            <Divider />
            <Grid container direction='column' alignItems='flex-start'>
              {boxLoading ? undefined : <RelatedButton resource={boxes} filter={{ id: boxIds }} />}
              {doorLoading ? undefined
                : <RelatedButton resource={doors} filter={{ id: doorIds }} />}
              <RelatedButton resource={properties} filter={{ owner: (record || {}).id }} />
              {/* <RelatedButton label='Guest Keys' resource={keys} filter={{ guest: (record || {}).id }} /> */}
              <RelatedButton
                resource={organizations}
                filter={{ id: (record || {}).organization }}
              />
              <RelatedButton resource={apikeys} filter={{ user: (record || {}).id }} />
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Aside;
