import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  BooleanField,
  ChipField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';
import TextArrayField from '../../components/TextArrayField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Description' source='description' />
      {/* <ReferenceArrayField label='Guests' source='guest' reference='users'>
        <SingleFieldList linkType='show'>
          <ChipField source='id' />
        </SingleFieldList>
      </ReferenceArrayField> */}
      <ReferenceField label='Guest' source='guest' reference='users' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceArrayField label='Doors' source='doors' reference='doors'>
        <SingleFieldList linkType='show'>
          <ChipField source='id' />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField label='Numeric Code' source='numericCode' />
      <TextField label='SMS Token' source='smsToken' />
      <TextField label='Link Token' source='linkToken' />
      <BooleanField label='Expiring?' source='isExpiring' />
      <BooleanField label='Booking?' source='isBooking' />
      <TextField label='Booking ID' source='bookingId' />
      <TextArrayField
        label='Can Use'
        source='canUse'
        choices={[
          { id: 'app', name: 'App' },
          { id: 'keypad', name: 'Keypad' },
          { id: 'sms', name: 'SMS' },
        ]}
      />
      <TextArrayField label='Tags' source='tags' />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
