import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='URL' source='url' type='url' resettable />
      <ArrayInput label='Doors' source='doors'>
        <SimpleFormIterator>
          <ReferenceInput label='Door' source={undefined as any} reference='doors' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      {/* <BooleanInput label='Active?' source='isActive' /> */}
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
