import * as React from 'react';
import { FC } from 'react';
import { ChipField, Record } from 'react-admin';

const TextArrayField: FC<{
  record?: Record,
  source?: string,
  choices?: any[],
  optionValue?: string,
  optionText?: string,
  clickable?: boolean,
  label?: string,
  addLabel?: boolean,
}> = ({
  record,
  source,
  // options,
  choices = [],
  optionValue = 'id',
  optionText = 'name',
  clickable,
  children,
  ...props
}) => (
  <>
    {((source || '').split('.')
      .reduce((acc: any, key: string, index: number, array: any[]) => (index === array.length - 1
        ? acc[key] : (acc[key] || {})), record) || [])
      .map((item: any) => (
        <ChipField
          record={{
            id: (record || {}).id || 0,
            value: (choices.find((i: any = {}) => i[optionValue] === item)
              || { [optionText]: item })[optionText],
          }}
          source='value'
          clickable={clickable}
          {...props}
        />
      ))}
  </>
);
TextArrayField.defaultProps = {
  record: undefined,
  source: '',
  choices: [],
  optionValue: 'id',
  optionText: 'name',
  clickable: false,
  label: '',
  addLabel: true,
};

export default TextArrayField;
