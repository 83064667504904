import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  required,
} from 'react-admin';

import Title from './Title';
import MACInput from '../../components/MACInput';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <SelectInput
        source='type'
        choices={[
          { id: 'wired', name: 'Wired' },
          { id: 'BLE', name: 'BLE' },
        ]}
        optionValue='id'
        optionText='name'
        validate={required()}
      />
      <FormDataConsumer>
        {({ formData, ...rest }: any) => (formData || {}).type === 'ble' && (
          <MACInput {...rest} label='MAC' source='mac' resettable required />
        )}
      </FormDataConsumer>
      <BooleanInput label='Active?' source='isActive' defaultValue />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
