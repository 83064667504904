import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  FunctionField,
} from 'react-admin';
// import { GMapField } from '@fusionworks/ra-google-maps-input';

// import config from '../../config';
import Title from './Title';
import DateField from '../../components/DateField';

const ResourceExpand: FC<any> = (props) => {
  const { record } = props;
  return (
    <Show
      title={<Title expand />}
      {...props}
      actions={<div />}
    >
      <SimpleShowLayout>
        <TextField label='Description' source='description' />
        <ReferenceArrayField label='Owners' source='owners' reference='users'>
          <SingleFieldList linkType='show'>
            <ChipField source='id' />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label='Address' source='address.formatted' />
        <TextField label='Timezone' source='timezone' emptyText='Etc/UTC' />
        {(record || {}).coordinates ? (
          // <GMapField
          //   lable='Map'
          //   source='coordinates'
          //   googleKey={config.GOOGLE_MAPS_KEY}
          // />
          undefined
        ) : undefined}
        <FunctionField
          label='Default Check In'
          render={(r: any) => `${`${Math.floor((r.defaultCheckIn || 0) / 60)}`
            .padStart(2, '0')}:${`${(r.defaultCheckIn || 0) % 60}`.padStart(2, '0')}`}
        />
        <FunctionField
          label='Default Check Out'
          render={(r: any) => `${`${Math.floor((r.defaultCheckOut || 0) / 60)}`
            .padStart(2, '0')}:${`${(r.defaultCheckOut || 0) % 60}`.padStart(2, '0')}`}
        />
        <DateField label='Updated At' source='dateUpdated' showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ResourceExpand;
