import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  BooleanInput,
  required,
} from 'react-admin';
// import RichTextInput from 'ra-input-rich-text';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      {/* <SectionTitle label='resources.customers.fieldGroups.password' /> */}
      <TextInput label='Name' source='name' resettable />
      {/* <RichTextInput label='Description' source='description' /> */}
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ReferenceInput label='User' source='user' reference='users' resettable autoFocus validate={required()}>
        <TextInput source='id' />
      </ReferenceInput>
      <BooleanInput label='Active?' source='isActive' defaultValue />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
