import * as React from 'react';
import { FC } from 'react';
import { Card, Box, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const AppError: FC<any> = ({ error, errorInfo }) => {
  const classes = makeStyles((theme) => ({
    root: {
      background: '#535353',
      color: '#fff',
      padding: 20,
      marginTop: theme.spacing(2),
      marginBottom: '1em',
    },
  }))();
  return (
    <Card className={classes.root}>
      <Box display='flex'>
        <Box flex='1'>
          <Typography variant='h3' component='h2' gutterBottom>
            An error has occurred!
          </Typography>
          <Typography variant='h5' component='h2' gutterBottom>
            Please show the following information to a developer:
          </Typography>
          <Box maxWidth='40em'>
            <Typography variant='body1' component='p' gutterBottom>
              {error.toString()}
            </Typography>
            <Typography variant='body1' component='p' gutterBottom>
              {errorInfo.componentStack}
            </Typography>
          </Box>
          {/* <BackButton /> */}
        </Box>
      </Box>
    </Card>
  );
};

export default AppError;
