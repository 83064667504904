import * as React from 'react';
import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Tooltip, Button, Box } from '@material-ui/core';
import { stringify } from 'qs';

const Aside: FC<any> = ({ resource, filter, label }) => (
  <Tooltip title={resource.label}>
    <Button
      size='small'
      color='primary'
      component={Link}
      to={{
        pathname: `/${resource.name}`,
        search: stringify({
          // page: 1,
          // perPage: 20,
          // sort: 'dateCreated',
          // order: 'ASC',
          filter: JSON.stringify(filter),
        }),
      }}
    >
      <resource.Icon fontSize='small' />
      <Box paddingLeft='0.5em'>
        {label || resource.label}
      </Box>
    </Button>
  </Tooltip>
);

export default Aside;
