import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  BooleanField,
  FunctionField,
  UrlField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import MACField from '../../components/MACField';
import DateField from '../../components/DateField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`Box ${(data || {}).id} | ${(data || {}).mac}`} />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<Actions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      {/* <NumberField label='ID' source='id' textAlign='left' options={{ useGrouping: false }} /> */}
      {/* <NumberField source='total' options={{ style: 'currency', currency: 'USD' }} className={classes.total} /> */}
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <TextField label='Description' source='description' />
      <MACField label='MAC' source='mac' />
      <TextField label='Serial Number' source='serialNumber' />
      <BooleanField label='Online?' source='isOnline' />
      <TextField label='Hardware Version' source='versionHw' />
      <TextField label='Firmware Version' source='versionFw' />
      <TextField label='Factory Firmware Version' source='versionFwFactory' />
      <FunctionField
        label='Broker URL'
        render={(r: any) => ((r || {}).mac ? (
          <UrlField
            label='Broker URL'
            record={{ id: '', value: `https://pubsub.homeit.io/#/clients/${r.mac}` }}
            source='value'
          />
        ) : '')}
      />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
