import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  BooleanField,
  ReferenceField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../../../components/CopyButton';
import DateField from '../../../../components/DateField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`Listing ${(data || {}).id} | ${(data || {}).url}`} />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<Actions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <ReferenceField label='Organization' source='organization' reference='organizations' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <TextField label='Account' source='account' />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
