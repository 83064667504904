// import * as React from 'react';
// import { FC } from 'react';
// import { FunctionField } from 'react-admin';
import { TextField } from 'react-admin';

// const MACField: FC<{
//   record?: any,
//   source: string,
//   label?: string,
//   addLabel?: boolean,
//   [prop: string]: any,
// }> = ({
//   record,
//   source,
//   label,
//   ...props
// }) => (
//   <>
//     <FunctionField
//       record={record}
//       source={source || 'mac'}
//       label={label || 'MAC'}
//       {...props}
//       render={() => {
//         const field = (source || 'mac').split('.')
//           .reduce((acc, key, index, array) => (index === array.length - 1
//             ? acc[key] : (acc[key] || {})), record) || '';
//         return field;
//       // return (/[0-9a-f]{12}/.test(field)
//       //   ? field.toUpperCase().match(/.{1,2}/g).join(':')
//       //   : field);
//       }}
//     />
//   </>
// );
// MACField.defaultProps = { addLabel: true };

// export default MACField;
export default TextField;
