import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  List,
  TextField,
  SelectField,
  NumberField,
  ReferenceField,
  Pagination,
  FunctionField,
  ShowButton,
} from 'react-admin';

import Expand from './Expand';
import Filter from './Filter';
import Title from './Title';
import DateField from '../../components/DateField';

const ResourceList: FC<any> = (props) => (
  <List
    {...props}
    title={<Title />}
    filterDefaultValues={{}}
    sort={{ field: 'dateCreated', order: 'DESC' }}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
    filters={<Filter />}
  >
    <Datagrid
      expand={<Expand />}
      optimized
    >
      <NumberField label='#' source='index' sortable={false} options={{ useGrouping: false }} />
      {/* <TextField label='ID' source='id' /> */}
      <DateField label='Timestamp' source='timestamp' showTime />
      <SelectField
        source='source'
        choices={[
          { id: 'keypad', name: 'Keypad' },
          { id: 'app', name: 'App (guest)' },
          { id: 'owner', name: 'App (host)' },
          { id: 'sms', name: 'SMS' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <ReferenceField label='Door' source='door' reference='doors' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceField label='Key' source='key' reference='keys' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceField label='Guest' source='guest' reference='users' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <DateField label='Created At' source='dateCreated' showTime />
      <FunctionField
        textAlign='right'
        render={(r: any) => (
          <>
            <ShowButton basePath={props.basePath} record={r} />
          </>
        )}
      />
    </Datagrid>
  </List>
);

export default ResourceList;
