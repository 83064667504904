import * as React from 'react';
import { FC } from 'react';
import {
  // SearchInput,
  TextInput,
  DateInput,
  BooleanInput,
  ReferenceInput,
  SelectInput,
  Filter,
} from 'react-admin';

const ResourceFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <SearchInput source='name' alwaysOn /> */}
    <TextInput label='ID' source='id' alwaysOn />
    <TextInput label='Name' source='name' alwaysOn />
    <ReferenceInput label='Guest' source='guest' reference='users'>
      <TextInput source='id' />
    </ReferenceInput>
    <ReferenceInput label='Door' source='door' reference='doors'>
      <TextInput source='id' />
    </ReferenceInput>
    <BooleanInput label='Expiring' source='isExpiring' />
    <DateInput label='Check In Before' source='checkInBefore' />
    <DateInput label='Check In After' source='checkInAfter' />
    <DateInput label='Check Out Before' source='checkOutBefore' />
    <DateInput label='Check Out After' source='checkOutAfter' />
    <BooleanInput label='Booking' source='isBooking' />
    <SelectInput
      label='Can Use'
      source='canUse'
      choices={[
        { id: 'app', name: 'App' },
        { id: 'keypad', name: 'Keypad' },
        { id: 'sms', name: 'SMS' },
      ]}
    />
    <BooleanInput label='Active' source='isActive' />
    <DateInput label='Created Before' source='createdBefore' />
    <DateInput label='Created After' source='createdAfter' />
  </Filter>
);

export default ResourceFilter;
