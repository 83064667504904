import * as React from 'react';
import { FC } from 'react';
import {
  TextInput,
  required,
  regex,
  TextInputProps,
} from 'react-admin';

const MACInput: FC<TextInputProps & { required?: boolean }> = ({
  source,
  required: req,
  label,
  ...props
}) => (
  <>
    <TextInput
      label={label || 'MAC'}
      source={source || 'mac'}
      {...props}
      validate={[...(req === true ? [required()] : []), regex(/[0-9a-f]{12}/)]}
      parse={(v: string) => v.toLowerCase().replace(/[^0-9a-f]/g, '')}
    />
  </>
);
MACInput.defaultProps = { required: false };

export default MACInput;
