import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import Title from './Title';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
