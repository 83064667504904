import * as React from 'react';
import { useGetManyReference } from 'react-admin';
import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import RelatedButton from '../../components/RelatedButton';
import boxes from '../boxes';
import doors from '../doors';
import users from '../users';

const Aside: React.FC<any> = ({ record }) => {
  const { ids: boxIds, loading: boxLoading } = useGetManyReference('properties', 'boxes',
    (record || {}).id, { page: 1, perPage: 100 }, {} as any, {}, 'boxes', {});
  return (
    <>
      <Box margin='0 0 1em 1em'>
        <Card>
          <Box margin='1em'>
            <Typography>
              Related resources
            </Typography>
            <Divider />
            <Grid container direction='column' alignItems='flex-start'>
              {boxLoading ? undefined : <RelatedButton resource={boxes} filter={{ id: boxIds }} />}
              <RelatedButton resource={doors} filter={{ property: (record || {}).id }} />
              <RelatedButton label='Owners' resource={users} filter={{ id: (record || {}).owners }} />
            </Grid>
          </Box>
        </Card>
      </Box>
    </>
  );
};

export default Aside;
