import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  required,
} from 'react-admin';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <ReferenceInput label='Credentials' source='credentials' reference='avantioCredentials' resettable validate={required()}>
        <TextInput source='id' />
      </ReferenceInput>
      <TextInput label='Accommodation Code' source='accommodationCode' resettable validate={required()} />
      <ArrayInput label='Doors' source='doors'>
        <SimpleFormIterator>
          <ReferenceInput label='Door' source={undefined as any} reference='doors' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      {/* <BooleanInput label='Active?' source='isActive' defaultValue /> */}
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
