import Icon from '@material-ui/icons/ExitToApp';

import Title, { name, label } from './Title';
import List from './List';
import Show from './Show';

export default {
  name,
  label,
  Icon,
  Title,
  List,
  Show,
};
