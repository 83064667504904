import * as React from 'react';
import { FC } from 'react';

export type TitleProps = {
  record?: any,
  expand?: boolean,
  create?: boolean,
};

export const name = 'keys';
export const label = 'Keys';
const details = (record: any) => `${record.id} | ${record.name} | ${record.checkIn} | ${record
  .isExpiring === false ? 'Permanent' : record.checkOut}`;

export const Title: FC<TitleProps> = ({ record, expand, create }) => (
  <span>
    {`${expand === true ? '' : label}${record ? ` | ${create === true ? 'Create' : details(record)}` : ''}`}
  </span>
);

export default Title;
