import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  EmailField,
  BooleanField,
  ReferenceField,
  SelectField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import DateField from '../../components/DateField';
import TextArrayField from '../../components/TextArrayField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`User ${(data || {}).id} | ${(data || {}).name} | ${((data || {}).email || {}).address}`} />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<Actions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <EmailField label='Email' source='email.address' />
      <TextField label='Country' source='phone.country' />
      <TextField label='Phone Number' source='phone.number' />
      <ReferenceField label='Organization' source='organization' reference='organizations' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <SelectField
        label='Organization Role'
        source='organizationRole'
        choices={[
          { id: 'admin', name: 'Administrator' },
          { id: 'member', name: 'Member' },
          { id: 'billing', name: 'Billing Manager' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <TextArrayField
        label='Add-ons'
        source='billing.services'
        choices={[
          { id: 'service-keyevents-unlimited', name: 'Unlimited Activity' },
          { id: 'service-integrations-ical', name: 'Integration iCal' },
          { id: 'service-keys-unlimited', name: 'Unlimited Keys' },
          { id: 'service-keys-multiple-properties', name: 'Master Keys' },
          { id: 'service-keys-recover-expired', name: 'Recover Expired Keys' },
          { id: 'service-organization-add-users', name: 'Add Users' },
          { id: 'service-api-access', name: 'API Access' },
        ]}
      />
      <TextArrayField label='Scope' source='scopes' />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
