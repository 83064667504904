import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  NumberInput,
  FormDataConsumer,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ArrayInput label='Owners' source='owners'>
        <SimpleFormIterator>
          <ReferenceInput label='Owner' source={undefined as any} reference='users' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      {/* <TextInput label='Address' source='address' resettable /> */}
      <FormDataConsumer>
        {({ record, ...rest }: any) => (
          <TextInput
            {...rest}
            record={record}
            label='Address'
            source='address'
            format={() => ((record || {}).address || {}).formatted}
            resettable
            fullWidth
          />
        )}
      </FormDataConsumer>
      <NumberInput label='Default Check In' source='defaultCheckIn' min={0} max={24 * 60 - 1} defaultValue={14 * 60} />
      <NumberInput label='Default Check Out' source='defaultCheckOut' min={0} max={24 * 60 - 1} defaultValue={11 * 60} />
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
