import * as React from 'react';
import { FC } from 'react';
import {
  Box,
  Card,
  CardActions,
  Button,
  Typography,
} from '@material-ui/core';
import HomeIcon from '@material-ui/icons/Home';
// import CodeIcon from '@material-ui/icons/Code';
import { makeStyles } from '@material-ui/core/styles';

// import backgroundImage from './welcome_bg.png';
// import publishArticleImage from './welcome_illustration.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    background: '#535353',
    // background: theme.palette.type === 'dark'
    //   ? '#535353'
    //   : `url(${backgroundImage}) no-repeat #6f4ceb`,
    color: '#fff',
    padding: 20,
    marginTop: theme.spacing(2),
    marginBottom: '1em',
  },
  // media: {
  //   background: `url(${publishArticleImage}) top right / cover`,
  //   marginLeft: 'auto',
  // },
  actions: {
    [theme.breakpoints.down('md')]: {
      padding: 0,
      flexWrap: 'wrap',
      '& a': {
        marginTop: '1em',
        marginLeft: '0!important',
        marginRight: '1em',
      },
    },
  },
}));

const Welcome: FC = () => {
  const classes = useStyles();
  return (
    <Card className={classes.root}>
      <Box display='flex'>
        <Box flex='1'>
          <Typography variant='h5' component='h2' gutterBottom>
            Backoffice | homeit
          </Typography>
          <Box maxWidth='40em'>
            <Typography variant='body1' component='p' gutterBottom>
              Welcome to the Backoffice.
              Please forward any suggestions or bug reports to the developers.
            </Typography>
          </Box>
          <Typography variant='h6' component='h3' gutterBottom>
            Changelog
          </Typography>
          <Box maxWidth='40em'>
            <Typography variant='body1' component='p' gutterBottom>
              v1.1.0 (2021-10-05)
              <ul>
                <li>Fix serving index file without injected environment variables</li>
              </ul>
              v1.0.8 (2021-08-23)
              <ul>
                <li>Fix errors when requesting related resources</li>
              </ul>
              v1.0.7 (2021-06-02)
              <ul>
                <li>Add connection status to lock and keypad pages</li>
                <li>Fix white page when updating a key</li>
              </ul>
              v1.0.6 (2021-03-11)
              <ul>
                <li>Fix MAC column ordering</li>
                <li>Fix white page when opening a key in a new page</li>
              </ul>
              v1.0.5 (2021-02-12)
              <ul>
                <li>Add settings page</li>
                <li>Format dates as ISO8601</li>
              </ul>
              v1.0.4 (2021-01-16)
              <ul>
                <li>Add multi-resource related links</li>
              </ul>
              v1.0.3 (2020-12-28)
              <ul>
                <li>Add authentication to BOXv1 Configuration Page</li>
                <li>Add Avantio integration</li>
                <li>Rearrange integration resources in menu</li>
                <li>Fix key update validation</li>
              </ul>
              v1.0.2 (2020-11-04)
              <ul>
                <li>Fix create actions including sorting and pagination query parameters</li>
              </ul>
              v1.0.1 (2020-10-28)
              <ul>
                <li>Remove delete button from list and show views</li>
                <li>Remove generic search field</li>
                <li>Always show most used search fields</li>
                <li>Fix white page when navigating directly to a resource page</li>
                <li>Fix key title showing check out date for permanent keys</li>
              </ul>
              v1.0.0 (2020-09-29)
              <ul>
                <li>First release</li>
              </ul>
            </Typography>
          </Box>
          <CardActions className={classes.actions}>
            <Button
              variant='contained'
              href='https://homeit.io'
              startIcon={<HomeIcon />}
            >
              Homepage
            </Button>
          </CardActions>
        </Box>
        {/* <Box
          display={{ xs: 'none', sm: 'none', md: 'block' }}
          width='15em'
          overflow='hidden'
        >
          <Box height='9em' width='15em' className={classes.media} />
        </Box> */}
      </Box>
    </Card>
  );
};

export default Welcome;
