import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  ReferenceField,
  SelectField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';
import TextArrayField from '../../components/TextArrayField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Country' source='phone.country' />
      <TextField label='Phone Number' source='phone.number' />
      <ReferenceField label='Organization' source='organization' reference='organizations' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <SelectField
        label='Organization Role'
        source='organizationRole'
        choices={[
          { id: 'admin', name: 'Administrator' },
          { id: 'member', name: 'Member' },
          { id: 'billing', name: 'Billing Manager' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <TextArrayField label='Scope' source='scopes' />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
