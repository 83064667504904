import * as React from 'react';
import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import RelatedButton from '../../components/RelatedButton';
import boxes from '../boxes';
import properties from '../properties';
import locks from '../locks';
import keypads from '../keypads';
import keys from '../keys';
import keyevents from '../keyevents';
import icalListings from '../integrations/ical/listings';
import avantioListings from '../integrations/avantio/listings';

const Aside: React.FC<any> = ({ record }) => (
  <>
    <Box margin='0 0 1em 1em'>
      <Card>
        <Box margin='1em'>
          <Typography>
            Related resources
          </Typography>
          <Divider />
          <Grid container direction='column' alignItems='flex-start'>
            <RelatedButton resource={boxes} filter={{ id: (record || {}).box }} />
            <RelatedButton resource={properties} filter={{ id: (record || {}).property }} />
            <RelatedButton resource={locks} filter={{ id: (record || {}).locks }} />
            <RelatedButton resource={keypads} filter={{ id: (record || {}).keypads }} />
            <RelatedButton resource={keys} filter={{ door: (record || {}).id }} />
            <RelatedButton resource={keyevents} filter={{ door: (record || {}).id }} />
            <RelatedButton
              label='iCal Listings'
              resource={icalListings}
              filter={{ door: (record || {}).id }}
            />
            <RelatedButton
              label='Avantio Listings'
              resource={avantioListings}
              filter={{ door: (record || {}).id }}
            />
          </Grid>
        </Box>
      </Card>
    </Box>
  </>
);

export default Aside;
