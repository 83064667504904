import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';
import TextArrayField from '../../components/TextArrayField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Description' source='description' />
      <TextField label='Token' source='token' />
      <TextArrayField label='Scope' source='scope' />
      <DateField label='Expires At' source='expires' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
