import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  BooleanField,
  ReferenceField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
  UrlField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import DateField from '../../components/DateField';
import TextArrayField from '../../components/TextArrayField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`Key ${(data || {}).id} | ${(data || {}).name} | ${(data || {})
      .checkIn} | ${(data || {}).isExpiring === false ? 'Permanent' : (data || {}).checkOut}`}
    />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<Actions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <TextField label='Description' source='description' />
      <BooleanField label='Expiring?' source='isExpiring' />
      <DateField label='Check In' source='checkIn' showTime />
      <DateField label='Check Out' source='checkOut' showTime />
      <ReferenceField label='Guest' source='guest' reference='users' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <ReferenceArrayField label='Doors' source='doors' reference='doors'>
        <SingleFieldList linkType='show'>
          <ChipField source='id' />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextField label='Numeric Code' source='numericCode' />
      <TextField label='SMS Token' source='smsToken' />
      <TextField label='Link Token' source='linkToken' />
      <UrlField label='Link URL' source='linkUrl' />
      <BooleanField label='Booking?' source='isBooking' />
      <TextField label='Booking ID' source='bookingId' />
      <TextArrayField
        label='Can Use'
        source='canUse'
        choices={[
          { id: 'app', name: 'App' },
          { id: 'keypad', name: 'Keypad' },
          { id: 'sms', name: 'SMS' },
        ]}
      />
      <TextArrayField label='Tags' source='tags' />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
