import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  BooleanField,
  FunctionField,
  SelectField,
} from 'react-admin';

import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import MACField from '../../components/MACField';
import DateField from '../../components/DateField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`Lock ${(data || {}).id} | ${(data || {}).type} | ${(data || {}).mac}`} />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => (
  <Show
    title={<Title />}
    actions={<Actions />}
    aside={<Related />}
    {...props}
  >
    <SimpleShowLayout>
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <TextField label='Description' source='description' />
      <SelectField
        source='type'
        choices={[
          { id: 'passive', name: 'Passive' },
          { id: 'active', name: 'Active' },
          { id: 'ble', name: 'BLE' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <MACField label='MAC' source='mac' />
      <TextField label='Model' source='model' emptyText='No model' />
      <TextField label='Hardware Version' source='versionHw' emptyText='No version' />
      <TextField label='Firmware Version' source='versionFw' emptyText='No version' />
      <FunctionField
        label='Battery Level'
        render={(r: any) => ((r || {}).batteryLevel ? `${r.batteryLevel}%` : '')}
      />
      {/* <NumberField source='batteryLevel' options={{ style: 'percent' }} /> */}
      <BooleanField label='Connected?' source='isConnected' />
      <FunctionField
        label='Timing'
        render={(r: any) => ((r || {}).timing ? `${r.timing}s` : '')}
      />
      <SelectField
        source='lockingType'
        choices={[
          { id: 'latch', name: 'Latch' },
          { id: 'full', name: 'Full' },
          { id: '1-turn', name: '1-Turn' },
          { id: '2-turn', name: '2-Turn' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceShow;
