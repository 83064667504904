import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <TextInput label='VAT' source='vatin' resettable />
      <TextInput label='Customer ID' source='customerId' resettable />
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
