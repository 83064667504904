import * as React from 'react';
import { FC } from 'react';
import { useSelector } from 'react-redux';
import { Layout, Sidebar } from 'react-admin';

import AppBar from './AppBar';
import Menu from './Menu';
import AppError from './AppError';

const CustomSidebar = (props: any) => <Sidebar {...props} size={200} />;

const AppLayout: FC<any> = (props: any) => {
  const theme = useSelector(() => ({
    palette: {
      primary: {
        main: '#90caf9',
      },
      type: 'dark', // Switching the dark mode on is a single property value change.
    },
    overrides: {
      MuiAppBar: {
        colorSecondary: {
          backgroundColor: '#00806F',
        },
      },
    },
  }));
  return (
    <Layout
      {...props}
      appBar={AppBar}
      sidebar={CustomSidebar}
      menu={Menu}
      theme={theme}
      error={<AppError />}
    />
  );
};

export default AppLayout;
