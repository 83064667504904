import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  Pagination,
  FunctionField,
  EditButton,
  ShowButton,
  // BulkExportButton,
  // BulkDeleteButton,
} from 'react-admin';

import Expand from './Expand';
import Filter from './Filter';
import Title from './Title';
import DateField from '../../components/DateField';

// const exporter: FC<any> = (records, fetchRelatedRecords) =>
//   // will call dataProvider.getMany('posts', { ids: records.map(record => record.post_id) }), ignoring duplicate and empty post_id
//   fetchRelatedRecords(records, 'post_id', 'posts').then((posts) => {
//     const data = records.map((record) => ({
//       ...record,
//       post_title: posts[record.post_id].title,
//     }));
//     jsonExport(data, {
//       headers: ['id', 'post_id', 'post_title', 'body'],
//     }, (err, csv) => {
//       downloadCSV(csv, 'comments');
//     });
//   });

const ResourceList: FC<any> = (props) => (
  <List
    {...props}
    title={<Title />}
    filterDefaultValues={{}}
    sort={{ field: 'dateCreated', order: 'DESC' }}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
    filters={<Filter />}
    // exporter={exporter}
    // actions={(p: any) => {
    //   const {
    //     currentSort,
    //     resource,
    //     filterValues,
    //     basePath,
    //     total,
    //   } = useListContext();
    //   return (
    //     <TopToolbar>
    //       <FilterButton fields={['reference', 'sales', 'stock']} />
    //       <SortButton fields={['reference', 'sales', 'stock']} />
    //       <CreateButton basePath={basePath} />
    //       <ExportButton
    //         disabled={total === 0}
    //         resource={resource}
    //         sort={currentSort}
    //         filterValues={filterValues}
    //         maxResults={maxResults}
    //       />
    //     </TopToolbar>
    //   );
    // }}
    // bulkActionButtons={(p: any) => (
    //   <>
    //     <BulkExportButton {...p} />
    //     <BulkDeleteButton {...p} />
    //   </>
    // )}
  >
    <Datagrid
      expand={<Expand />}
      optimized
    >
      <NumberField label='#' source='index' sortable={false} options={{ useGrouping: false }} />
      <TextField label='ID' source='id' />
      <TextField label='Name' source='name' />
      <ReferenceField label='User' source='user' reference='users' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <FunctionField
        textAlign='right'
        render={(r: any) => (
          <>
            <EditButton basePath={props.basePath} record={r} />
            <ShowButton basePath={props.basePath} record={r} />
          </>
        )}
      />
    </Datagrid>
  </List>
);

export default ResourceList;
