import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  BooleanInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    // aside={<Basket />}
    // classes={classes}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ReferenceInput label='Box' source='box' reference='boxes' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <ReferenceInput label='Property' source='property' reference='properties' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <ArrayInput label='Locks' source='locks'>
        <SimpleFormIterator>
          <ReferenceInput label='Lock' source={undefined as any} reference='locks' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput label='Keypads' source='keypads'>
        <SimpleFormIterator>
          <ReferenceInput label='Keypad' source={undefined as any} reference='keypads' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
