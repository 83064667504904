import * as React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'proxy-polyfill';
import ReactDOM from 'react-dom';

import App from './client/App';
import './index.css';

ReactDOM.render(<App />, document.getElementById('root'));
