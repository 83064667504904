import Icon from '@material-ui/icons/Memory';

import Title, { name, label } from './Title';
import List from './List';
import Create from './Create';
import Show from './Show';
import Edit from './Edit';

export default {
  name,
  label,
  Icon,
  Title,
  List,
  Create,
  Show,
  Edit,
};
