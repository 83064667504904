import * as React from 'react';
// import { Redirect } from 'react-router-dom';
import { usePermissions } from 'ra-core';

import config from '../../config';

const AdminLogin: React.FC = () => {
  const { loaded, permissions } = usePermissions();
  if (!loaded) return null;
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = Array(6).fill(0)
    .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
    .join('');
  const redirectURI = `${config.BASE_URL || ''}/login`;
  const clientId = config.AUTH_CLIENT_ID || 'sgpwn2yw3k9ztxezpi3mnfpk53bvj7qtbtg4';
  const scope = 'admin';
  const url = `${config.BASE_URL_AUTH || 'https://auth.homeit.io/v2'}/oauth/login?redirect_uri=${redirectURI}&client_id=${clientId}&response_type=code&scope=${scope}&state=${state}`;
  if (permissions) window.location.href = `${config.BASE_URL || ''}/`;
  else window.location.href = url;
  return null;
  // return permissions ? <Redirect to={`${config.BASE_URL || ''}/`} /> : <Redirect to={url} />;
};

export default AdminLogin;
