import { AuthProvider } from 'ra-core';
import cookies from 'js-cookie';
import axios from 'axios';

import config from '../config';

export class UnknownMethodError extends Error {
  constructor() {
    super('Unknown method');
  }
}

setTimeout(() => axios.get(`${config.BASE_URL || ''}/refresh`), 50 * 60 * 1000);

export const provider: AuthProvider = {
  login: () => Promise.resolve(), // not used
  logout: () => {
    cookies.remove('authTokenRefresh');
    return cookies.get('authToken')
      ? axios.post(`${config.BASE_URL_AUTH || ''}/oauth/revoke`, {},
        { headers: { Authorization: `Bearer ${cookies.get('authToken')}` } })
        .then(() => cookies.remove('authToken'))
        .catch(() => undefined)
      : Promise.resolve();
  },
  // logout: () => {
  //   cookies.remove('authToken');
  //   cookies.remove('authTokenRefresh');
  //   return Promise.resolve();
  // },
  checkError: () => Promise.resolve(),
  checkAuth: () => {
    if (cookies.get('authToken')) return Promise.resolve();
    if (!cookies.get('authTokenRefresh')) return Promise.reject();
    return axios.get(`${config.BASE_URL || ''}/refresh`);
  },
  getPermissions: () => (cookies.get('authTokenScope')
    ? Promise.resolve(JSON.parse(cookies.get('authTokenScope') || ''))
    : Promise.reject()),
  // getPermissions: () => Promise.reject(new UnknownMethodError()),
};

export default provider;
