import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  FormDataConsumer,
  SelectInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Email' source='email.address' type='email' resettable />
      <TextInput label='Phone Number' source='phone.number' resettable />
      <ReferenceInput label='Organization' source='organization' reference='organizations' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <FormDataConsumer>
        {({ formData, ...rest }: any) => (formData || {}).organization && (
          <SelectInput
            {...rest}
            label='Organization Role'
            source='organizationRole'
            choices={[
              { id: 'admin', name: 'Administrator' },
              { id: 'member', name: 'Member' },
              // { id: 'billing', name: 'Billing Manager' },
            ]}
          />
        )}
      </FormDataConsumer>
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
