import * as React from 'react';
import { FC } from 'react';
import {
  Datagrid,
  List,
  TextField,
  NumberField,
  BooleanField,
  ReferenceField,
  Pagination,
  FunctionField,
  EditButton,
  ShowButton,
} from 'react-admin';

import Expand from './Expand';
import Filter from './Filter';
import Title from './Title';
import DateField from '../../../../components/DateField';

const ResourceList: FC<any> = (props) => (
  <List
    {...props}
    title={<Title />}
    filterDefaultValues={{}}
    sort={{ field: 'dateCreated', order: 'DESC' }}
    perPage={20}
    pagination={<Pagination rowsPerPageOptions={[10, 20, 50, 100]} />}
    filters={<Filter />}
  >
    <Datagrid
      expand={<Expand />}
      optimized
    >
      <NumberField label='#' source='index' sortable={false} options={{ useGrouping: false }} />
      <TextField label='ID' source='id' />
      <ReferenceField label='Organization' source='organization' reference='organizations' link='show'>
        <TextField source='id' />
      </ReferenceField>
      <TextField label='Account' source='account' />
      <BooleanField label='Active?' source='isActive' />
      <DateField label='Created At' source='dateCreated' showTime />
      <FunctionField
        textAlign='right'
        render={(r: any) => (
          <>
            <EditButton basePath={props.basePath} record={r} />
            <ShowButton basePath={props.basePath} record={r} />
          </>
        )}
      />
    </Datagrid>
  </List>
);

export default ResourceList;
