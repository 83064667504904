import * as React from 'react';
import { FC } from 'react';
import {
  // SearchInput,
  TextInput,
  DateInput,
  BooleanInput,
  Filter,
} from 'react-admin';

const ResourceFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <SearchInput source='name' alwaysOn /> */}
    <TextInput label='ID' source='id' alwaysOn />
    <TextInput label='Name' source='name' alwaysOn />
    <TextInput label='VAT' source='vatin' />
    <BooleanInput label='Active' source='isActive' />
    <DateInput label='Created Before' source='createdBefore' />
    <DateInput label='Created After' source='createdAfter' />
  </Filter>
);

export default ResourceFilter;
