import * as React from 'react';
import { FC } from 'react';
import {
  // SearchInput,
  TextInput,
  DateInput,
  BooleanInput,
  ReferenceInput,
  Filter,
} from 'react-admin';

const ResourceFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <SearchInput source='name' alwaysOn /> */}
    <TextInput label='ID' source='id' alwaysOn />
    <TextInput label='Name' source='name' alwaysOn />
    <TextInput label='Email' source='email' type='email' alwaysOn />
    <TextInput label='Country' source='country' />
    <TextInput label='Phone' source='phone' />
    <ReferenceInput label='Organization' source='organization' reference='organizations'>
      <TextInput source='id' />
    </ReferenceInput>
    <BooleanInput label='Active' source='isActive' />
    <DateInput label='Created Before' source='createdBefore' />
    <DateInput label='Created After' source='createdAfter' />
  </Filter>
);

export default ResourceFilter;
