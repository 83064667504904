import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  ReferenceInput,
  required,
} from 'react-admin';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <ReferenceInput label='Organization' source='organization' reference='organizations' resettable validate={required()}>
        <TextInput source='id' />
      </ReferenceInput>
      <TextInput label='Account' source='account' resettable validate={required()} />
      <TextInput label='Secret' source='secret' resettable validate={required()} />
      {/* <BooleanInput label='Active?' source='isActive' defaultValue /> */}
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
