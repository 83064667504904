import * as React from 'react';

import config from '../config';

const Login: React.FC<any> = () => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  const state = Array(6).fill(0)
    .map(() => characters.charAt(Math.floor(Math.random() * characters.length)))
    .join('');
  const redirectURI = `${config.BASE_URL}/login`; // window.location.href
  const clientId = config.LOGIN_CLIENT_ID;
  const loginRedirectURI = config.LOGIN_REDIRECT_URL;
  const scope = config.LOGIN_ROLE || 'operator';
  window.location.href = `${loginRedirectURI}?redirect_uri=${redirectURI}&client_id=${clientId}&response_type=code&scope=${scope}&state=${state}`;
  return null;
};

export default Login;
