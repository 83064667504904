import * as React from 'react';
import { FC } from 'react';
import { Route } from 'react-router-dom';
import { Admin, Resource } from 'react-admin';
// import polyglotI18nProvider from 'ra-i18n-polyglot';

// import './App.css';
import authProvider from './providers/auth.provider';
import dataProvider from './providers/data.provider';
import Login from './layout/Login';
import Layout from './layout/Layout';
import { Dashboard } from './dashboard';
import AdminLogin from './routes/admin/AdminLogin';
import Settings from './routes/settings/Settings';
import BoxConfiguration from './routes/v1config/BoxConfiguration';
import AppError from './layout/AppError';
// import englishMessages from './i18n/en';
// import frenchMessages from './i18n/fr';

import boxes from './resources/boxes';
import doors from './resources/doors';
import locks from './resources/locks';
import keypads from './resources/keypads';
import properties from './resources/properties';
import keys from './resources/keys';
import keyevents from './resources/keyevents';
import users from './resources/users';
import organizations from './resources/organizations';
import apikeys from './resources/apikeys';
import icalListings from './resources/integrations/ical/listings';
import avantioCredentials from './resources/integrations/avantio/credentials';
import avantioListings from './resources/integrations/avantio/listings';

// const i18nProvider = polyglotI18nProvider((locale) => (locale === 'fr'
//   ? frenchMessages : englishMessages), 'en');

const App: FC<any> = () => (
  <Admin
    title='Backoffice | homeit'
    dataProvider={dataProvider}
    customReducers={{ theme: (previousState = 'dark') => previousState }}
    customRoutes={[
      <Route exact path='/admin' render={() => <AdminLogin />} />,
      <Route exact path='/settings' render={() => <Settings />} />,
      <Route exact path='/v1config' render={() => <BoxConfiguration />} />,
      <Route
        exact
        path='/error'
        render={() => (
          <AppError
            label='Error'
            // error={new Error('Error test')}
            // errorInfo={{ componentStack: 'test' }}
          />
        )}
      />,
    ]}
    authProvider={authProvider}
    dashboard={Dashboard}
    loginPage={Login}
    layout={Layout}
    // i18nProvider={i18nProvider}
    disableTelemetry
  >
    <Resource
      name={boxes.name}
      list={boxes.List}
      create={boxes.Create}
      edit={boxes.Edit}
      show={boxes.Show}
      options={{ label: boxes.label }}
    />
    <Resource
      name={doors.name}
      list={doors.List}
      create={doors.Create}
      edit={doors.Edit}
      show={doors.Show}
      options={{ label: doors.label }}
    />
    <Resource
      name={locks.name}
      list={locks.List}
      create={locks.Create}
      edit={locks.Edit}
      show={locks.Show}
      options={{ label: locks.label }}
    />
    <Resource
      name={keypads.name}
      list={keypads.List}
      create={keypads.Create}
      edit={keypads.Edit}
      show={keypads.Show}
      options={{ label: keypads.label }}
    />
    <Resource
      name={properties.name}
      list={properties.List}
      create={properties.Create}
      edit={properties.Edit}
      show={properties.Show}
      options={{ label: properties.label }}
    />
    <Resource
      name={keys.name}
      list={keys.List}
      create={keys.Create}
      edit={keys.Edit}
      show={keys.Show}
      options={{ label: keys.label }}
    />
    <Resource
      name={keyevents.name}
      list={keyevents.List}
      show={keyevents.Show}
      options={{ label: keyevents.label }}
    />
    <Resource
      name={users.name}
      list={users.List}
      create={users.Create}
      edit={users.Edit}
      show={users.Show}
      options={{ label: users.label }}
    />
    <Resource
      name={organizations.name}
      list={organizations.List}
      create={organizations.Create}
      edit={organizations.Edit}
      show={organizations.Show}
      options={{ label: organizations.label }}
    />
    <Resource
      name={apikeys.name}
      list={apikeys.List}
      create={apikeys.Create}
      edit={apikeys.Edit}
      show={apikeys.Show}
      options={{ label: apikeys.label }}
    />
    <Resource
      name={icalListings.name}
      list={icalListings.List}
      create={icalListings.Create}
      edit={icalListings.Edit}
      show={icalListings.Show}
      options={{ label: icalListings.label }}
    />
    <Resource
      name={avantioCredentials.name}
      list={avantioCredentials.List}
      create={avantioCredentials.Create}
      edit={avantioCredentials.Edit}
      show={avantioCredentials.Show}
      options={{ label: avantioCredentials.label }}
    />
    <Resource
      name={avantioListings.name}
      list={avantioListings.List}
      create={avantioListings.Create}
      edit={avantioListings.Edit}
      show={avantioListings.Show}
      options={{ label: avantioListings.label }}
    />
  </Admin>
);

export default App;
