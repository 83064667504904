import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ReferenceInput,
  ArrayInput,
  SimpleFormIterator,
  required,
} from 'react-admin';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus validate={required()} />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ReferenceInput label='Property' source='property' reference='properties' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <ReferenceInput label='Box' source='box' reference='boxes' resettable>
        <TextInput source='id' />
      </ReferenceInput>
      <ArrayInput label='Locks' source='locks'>
        <SimpleFormIterator>
          <ReferenceInput label='Lock' source={undefined as any} reference='locks' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput label='Keypads' source='keypads'>
        <SimpleFormIterator>
          <ReferenceInput label='Keypad' source={undefined as any} reference='keypads' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label='Active?' source='isActive' defaultValue />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
