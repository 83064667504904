import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TopToolbar,
  ListButton,
  ExportButton,
  EditButton,
  CloneButton,
  TextField,
  BooleanField,
  FunctionField,
  ReferenceArrayField,
  SingleFieldList,
  ChipField,
} from 'react-admin';
// import { GMapField } from '@fusionworks/ra-google-maps-input';

// import config from '../../config';
import Title from './Title';
import Related from './Related';
import CopyButton from '../../components/CopyButton';
import DateField from '../../components/DateField';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} record={data} />
    <ExportButton basePath={basePath} record={data} />
    <EditButton basePath={basePath} record={data} />
    <CloneButton basePath={basePath} record={data} />
    <CopyButton text={`Property ${(data || {}).id} | ${(data || {}).name}`} />
    {/* <DeleteButton basePath={basePath} record={data} /> */}
  </TopToolbar>
);

const ResourceShow: FC<any> = (props) => {
  const { record } = props;
  return (
    <Show
      title={<Title />}
      actions={<Actions />}
      aside={<Related />}
      {...props}
    >
      <SimpleShowLayout>
        <TextField label='ID' source='id' />
        <TextField label='Name' source='name' />
        <TextField label='Description' source='description' />
        <ReferenceArrayField label='Owners' source='owners' reference='users'>
          <SingleFieldList linkType='show'>
            <ChipField source='id' />
          </SingleFieldList>
        </ReferenceArrayField>
        <TextField label='Address' source='address.formatted' />
        <TextField label='Timezone' source='timezone' emptyText='Etc/UTC' />
        {(record || {}).coordinates ? (
        // <GMapField
        //   lable='Map'
        //   source='coordinates'
        //   googleKey={config.GOOGLE_MAPS_KEY}
        // />
          undefined
        ) : undefined}
        <FunctionField
          label='Default Check In'
          render={(r: any) => ((r || {}).defaultCheckIn
            ? `${`${Math.floor((r.defaultCheckIn || 0) / 60)}`
              .padStart(2, '0')}:${`${(r.defaultCheckIn || 0) % 60}`.padStart(2, '0')}`
            : '11:00')}
        />
        <FunctionField
          label='Default Check Out'
          render={(r: any) => ((r || {}).defaultCheckOut
            ? `${`${Math.floor((r.defaultCheckOut || 0) / 60)}`
              .padStart(2, '0')}:${`${(r.defaultCheckOut || 0) % 60}`.padStart(2, '0')}`
            : '14:00')}
        />
        <BooleanField label='Active?' source='isActive' />
        <DateField label='Created At' source='dateCreated' showTime />
        <DateField label='Updated At' source='dateUpdated' showTime />
      </SimpleShowLayout>
    </Show>
  );
};

export default ResourceShow;
