import * as React from 'react';
import { FC, useState } from 'react';
import { useSelector } from 'react-redux';
import SettingsIcon from '@material-ui/icons/Settings';
import { useMediaQuery, Theme } from '@material-ui/core';
import { useTranslate, DashboardMenuItem, MenuItemLink } from 'react-admin';
import ResourcesIcon from '@material-ui/icons/LibraryBooks';
import IntegrationsIcon from '@material-ui/icons/Widgets';
import IntegrationIcon from '@material-ui/icons/AssignmentTurnedIn';
import MoreIcon from '@material-ui/icons/MoreHoriz';
import BoxConfigIcon from '@material-ui/icons/SettingsApplications';

import SubMenu from './SubMenu';
// import { AppState } from '../types';
import boxes from '../resources/boxes';
import doors from '../resources/doors';
import locks from '../resources/locks';
import keypads from '../resources/keypads';
import properties from '../resources/properties';
import keys from '../resources/keys';
import keyevents from '../resources/keyevents';
import users from '../resources/users';
import organizations from '../resources/organizations';
import apikeys from '../resources/apikeys';
import icalListings from '../resources/integrations/ical/listings';
import avantioCredentials from '../resources/integrations/avantio/credentials';
import avantioListings from '../resources/integrations/avantio/listings';

const Menu: FC<any> = ({ onMenuClick, dense, logout }) => {
  const [state, setState] = useState({
    expandResources: true,
    expandIntegrations: false,
    expandIcal: false,
    expandAvantio: false,
    expandMore: false,
  });
  const translate = useTranslate();
  const isXSmall = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  const open = useSelector((s) => (s as any).admin.ui.sidebarOpen);
  useSelector((s) => (s as any).theme); // force rerender on theme change
  return (
    <div>
      {' '}
      <DashboardMenuItem
        onClick={onMenuClick}
        sidebarIsOpen={open}
      />
      <SubMenu
        name='Resources'
        icon={<ResourcesIcon />}
        handleToggle={() => setState((s) => ({ ...s, expandResources: !s.expandResources }))}
        isOpen={state.expandResources}
        sidebarIsOpen={open}
        dense={dense}
      >
        <MenuItemLink
          to={`/${boxes.name}`}
          primaryText={boxes.label}
          leftIcon={<boxes.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${doors.name}`}
          primaryText={doors.label}
          leftIcon={<doors.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${locks.name}`}
          primaryText={locks.label}
          leftIcon={<locks.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${keypads.name}`}
          primaryText={keypads.label}
          leftIcon={<keypads.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${properties.name}`}
          primaryText={properties.label}
          leftIcon={<properties.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${keys.name}`}
          primaryText={keys.label}
          leftIcon={<keys.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${keyevents.name}`}
          primaryText={keyevents.label}
          leftIcon={<keyevents.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${users.name}`}
          primaryText={users.label}
          leftIcon={<users.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${organizations.name}`}
          primaryText={organizations.label}
          leftIcon={<organizations.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <MenuItemLink
          to={`/${apikeys.name}`}
          primaryText={apikeys.label}
          leftIcon={<apikeys.Icon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
        <SubMenu
          name='Integrations'
          icon={<IntegrationsIcon />}
          handleToggle={() => setState((s) => ({
            ...s,
            expandIntegrations: !s.expandIntegrations,
          }))}
          isOpen={state.expandIntegrations}
          sidebarIsOpen={open}
          dense={dense}
        >
          <SubMenu
            name='iCal'
            icon={<IntegrationIcon />}
            handleToggle={() => setState((s) => ({ ...s, expandIcal: !s.expandIcal }))}
            isOpen={state.expandIcal}
            sidebarIsOpen={open}
            dense={dense}
          >
            <MenuItemLink
              to={`/${icalListings.name}`}
              primaryText={icalListings.label}
              leftIcon={<icalListings.Icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
          <SubMenu
            name='Avantio'
            icon={<IntegrationIcon />}
            handleToggle={() => setState((s) => ({ ...s, expandAvantio: !s.expandAvantio }))}
            isOpen={state.expandAvantio}
            sidebarIsOpen={open}
            dense={dense}
          >
            <MenuItemLink
              to={`/${avantioCredentials.name}`}
              primaryText={avantioCredentials.label}
              leftIcon={<avantioCredentials.Icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
            <MenuItemLink
              to={`/${avantioListings.name}`}
              primaryText={avantioListings.label}
              leftIcon={<avantioListings.Icon />}
              onClick={onMenuClick}
              sidebarIsOpen={open}
              dense={dense}
            />
          </SubMenu>
        </SubMenu>
      </SubMenu>
      <SubMenu
        name='More'
        icon={<MoreIcon />}
        handleToggle={() => setState((s) => ({ ...s, expandMore: !s.expandMore }))}
        isOpen={state.expandMore}
        sidebarIsOpen={open}
        dense={dense}
      >
        <MenuItemLink
          to='/v1config'
          primaryText='BOXv1 Configuration'
          // primaryText={translate('resources.commands.name', { smart_count: 2 })}
          leftIcon={<BoxConfigIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      </SubMenu>
      {isXSmall && (
        <MenuItemLink
          to='/configuration'
          primaryText={translate('pos.configuration')}
          leftIcon={<SettingsIcon />}
          onClick={onMenuClick}
          sidebarIsOpen={open}
          dense={dense}
        />
      )}
      {isXSmall && logout}
    </div>
  );
};

export default Menu;
