import * as React from 'react';
import {
  Box,
  Card,
  Typography,
  Divider,
  Grid,
} from '@material-ui/core';

import RelatedButton from '../../components/RelatedButton';
import users from '../users';

const Aside: React.FC<any> = ({ record }) => (
  <>
    <Box margin='0 0 1em 1em'>
      <Card>
        <Box margin='1em'>
          <Typography>
            Related resources
          </Typography>
          <Divider />
          <Grid container direction='column' alignItems='flex-start'>
            <RelatedButton resource={users} filter={{ organization: (record || {}).id }} />
          </Grid>
        </Box>
      </Card>
    </Box>
  </>
);

export default Aside;
