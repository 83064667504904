import * as React from 'react';
import { FC } from 'react';
import {
  // SearchInput,
  TextInput,
  SelectInput,
  DateInput,
  ReferenceInput,
  Filter,
} from 'react-admin';

const ResourceFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <SearchInput source='door' alwaysOn /> */}
    <TextInput label='ID' source='id' />
    <DateInput label='Timestamp Before' source='timestampBefore' />
    <DateInput label='Timestamp After' source='timestampAfter' />
    <SelectInput
      source='source'
      choices={[
        { id: 'keypad', name: 'Keypad' },
        { id: 'app', name: 'App (guest)' },
        { id: 'owner', name: 'App (host)' },
        { id: 'sms', name: 'SMS' },
      ]}
      optionValue='id'
      optionText='name'
      alwaysOn
    />
    <ReferenceInput label='Door' source='door' reference='doors' alwaysOn>
      <TextInput source='id' />
    </ReferenceInput>
    <ReferenceInput label='Key' source='key' reference='keys'>
      <TextInput source='id' />
    </ReferenceInput>
    <ReferenceInput label='Guest' source='guest' reference='users'>
      <TextInput source='id' />
    </ReferenceInput>
    <DateInput label='Created Before' source='createdBefore' />
    <DateInput label='Created After' source='createdAfter' />
  </Filter>
);

export default ResourceFilter;
