import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
  NumberField,
  SelectField,
  FunctionField,
  BooleanField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Name' source='name' />
      <TextField label='Description' source='description' />
      <TextField label='Model' source='model' emptyText='No model' />
      <TextField label='Hardware Version' source='versionHw' emptyText='No version' />
      <TextField label='Firmware Version' source='versionFw' emptyText='No version' />
      <FunctionField
        label='Battery Level'
        render={(r: any) => (r.batteryLevel ? `${r.batteryLevel}%` : '')}
      />
      {/* <NumberField source='batteryLevel' options={{ style: 'percent' }} /> */}
      <BooleanField label='Connected?' source='isConnected' />
      <NumberField label='Timing' source='timing' options={{ useGrouping: false }} />
      <SelectField
        source='lockingType'
        choices={[
          { id: 'latch', name: 'Latch' },
          { id: 'full', name: 'Full' },
          { id: '1-turn', name: '1-Turn' },
          { id: '2-turn', name: '2-Turn' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
