import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  DateTimeInput,
  FormDataConsumer,
  SelectArrayInput,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      {/* <ReferenceInput label='Guest' source='guest' reference='users' resettable>
        <TextInput source='id' />
      </ReferenceInput> */}
      <ArrayInput label='Doors' source='doors'>
        <SimpleFormIterator>
          <ReferenceInput label='Door' source={undefined as any} reference='doors' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <BooleanInput label='Expiring' source='isExpiring' defaultValue />
      <DateTimeInput label='Check In' source='checkIn' />
      <FormDataConsumer>
        {({ formData, ...rest }: any) => (formData || {}).isExpiring === true && (
          <DateTimeInput {...rest} label='Check Out' source='checkOut' />
        )}
      </FormDataConsumer>
      <SelectArrayInput
        label='Can Use'
        source='canUse'
        choices={[
          { id: 'app', name: 'App' },
          { id: 'keypad', name: 'Keypad' },
          { id: 'sms', name: 'SMS' },
        ]}
      />
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
