import * as React from 'react';
import { FC } from 'react';
import {
  Show,
  SimpleShowLayout,
  TextField,
} from 'react-admin';

import Title from './Title';
import DateField from '../../components/DateField';

const ResourceExpand: FC<any> = (props) => (
  <Show
    title={<Title expand />}
    {...props}
    actions={<div />}
  >
    <SimpleShowLayout>
      <TextField label='Description' source='description' />
      <TextField label='VAT' source='vatin' />
      <TextField label='Customer ID' source='customerId' />
      <DateField label='Updated At' source='dateUpdated' showTime />
    </SimpleShowLayout>
  </Show>
);

export default ResourceExpand;
