import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  ArrayInput,
  SimpleFormIterator,
  ReferenceInput,
  NumberInput,
  required,
} from 'react-admin';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus validate={required()} />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <ArrayInput label='Owners' source='owners'>
        <SimpleFormIterator>
          <ReferenceInput label='Owner' source={undefined as any} reference='users' resettable>
            <TextInput source='id' />
          </ReferenceInput>
        </SimpleFormIterator>
      </ArrayInput>
      <TextInput label='Address' source='address' resettable fullWidth />
      <NumberInput label='Default Check In' source='defaultCheckIn' min={0} max={24 * 60 - 1} defaultValue={14 * 60} />
      <NumberInput label='Default Check Out' source='defaultCheckOut' min={0} max={24 * 60 - 1} defaultValue={11 * 60} />
      <BooleanInput label='Active?' source='isActive' defaultValue />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
