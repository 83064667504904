import * as React from 'react';
import { FC } from 'react';
import {
  Create,
  SimpleForm,
  TextInput,
  BooleanInput,
  required,
} from 'react-admin';

import Title from './Title';

const ResourceCreate: FC<any> = (props) => (
  <Create
    title={<Title create />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable autoFocus validate={required()} />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <TextInput label='VAT' source='vatin' resettable />
      <TextInput label='Customer ID' source='customerId' resettable />
      <BooleanInput label='Active?' source='isActive' defaultValue />
    </SimpleForm>
  </Create>
);

export default ResourceCreate;
