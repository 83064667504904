import * as React from 'react';
import { FC } from 'react';
import {
  Edit,
  SimpleForm,
  TextInput,
  BooleanInput,
  SelectInput,
  FormDataConsumer,
  TopToolbar,
  ListButton,
  ShowButton,
} from 'react-admin';

import Title from './Title';
import MACInput from '../../components/MACInput';

const Actions: FC<any> = ({ basePath, data }) => (
  <TopToolbar>
    <ListButton basePath={basePath} />
    <ShowButton basePath={basePath} record={data} />
  </TopToolbar>
);

const ResourceEdit: FC<any> = (props) => (
  <Edit
    title={<Title />}
    actions={<Actions />}
    {...props}
  >
    <SimpleForm redirect='show'>
      <TextInput label='Name' source='name' resettable />
      <TextInput label='Description' source='description' resettable multiline fullWidth />
      <SelectInput
        source='type'
        choices={[
          { id: 'passive', name: 'Passive' },
          { id: 'active', name: 'Active' },
          { id: 'ble', name: 'BLE' },
        ]}
        optionValue='id'
        optionText='name'
      />
      <FormDataConsumer>
        {({ formData, ...rest }: any) => (formData || {}).type === 'ble' && (
          <MACInput {...rest} label='MAC' source='mac' resettable required />
        )}
      </FormDataConsumer>
      <BooleanInput label='Active?' source='isActive' />
    </SimpleForm>
  </Edit>
);

export default ResourceEdit;
