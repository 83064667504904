import * as React from 'react';
import { FC } from 'react';
import {
  // SearchInput,
  TextInput,
  DateInput,
  BooleanInput,
  SelectInput,
  Filter,
} from 'react-admin';

import MACInput from '../../components/MACInput';

const ResourceFilter: FC<any> = (props) => (
  <Filter {...props}>
    {/* <SearchInput source='mac' alwaysOn /> */}
    <TextInput label='ID' source='id' alwaysOn />
    <TextInput label='Name' source='name' />
    <SelectInput
      source='type'
      choices={[
        { id: 'passive', name: 'Passive' },
        { id: 'active', name: 'Active' },
        { id: 'ble', name: 'BLE' },
      ]}
      optionValue='id'
      optionText='name'
      alwaysOn
    />
    <MACInput label='MAC' source='mac' alwaysOn />
    <BooleanInput label='Active' source='isActive' />
    <DateInput label='Created Before' source='createdBefore' />
    <DateInput label='Created After' source='createdAfter' />
  </Filter>
);

export default ResourceFilter;
