import * as React from 'react';
import { FC } from 'react';
import { Button, Box } from '@material-ui/core';
import CopyIcon from '@material-ui/icons/FileCopy';
import CopyToClipboard from 'react-copy-to-clipboard';

const CopyButton: FC<any> = ({ text }) => (
  <CopyToClipboard text={text}>
    <Button size='small' color='primary'>
      <CopyIcon fontSize='small' />
      <Box paddingLeft='0.5em'>
        Copy
      </Box>
    </Button>
  </CopyToClipboard>
);

export default CopyButton;
